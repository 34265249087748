<template>
  <div v-if="this.$route.name == 'Admin'">
    <Navigation v-bind:Breadcrumb="[
      { name: 'Home', route: 'Home' },
      { name: 'Staff', route: 'Admin' },
    ]" />
    <div class="placeholder">
      <div class="row">
      </div>
    </div>
  </div>
  <div v-else>
    <router-view />
  </div>
</template>

<script>
import { useStore } from "vuex";
document.title = "MicronAgritech | Admin";

import Navigation from "../components/Navigation.vue";
export default {
  name: "Admin",
  setup() {
    const store = useStore();

    return { store };
  },
  data() {
    return {};
  },
  components: {
    Navigation,
  },
  computed: {
    FullName() {
      return this.store.state["User"].info.name;
    },
    isStaff() {
      return this.store.state["User"].info.is_staff;
    },
    avatar() {
      return this.store.state["User"].info.avatar;
    },
  },
};
</script>

<style>
.card {
  width: 100%;
  height: auto;
  border-radius: 20px;
  border: 1px solid #efefef;
  box-sizing: border-box;
  padding: 10px;
}

.card .text {
  font-weight: 500;
  color: black;
}
</style>